@import url('../fonts/fonts.css');

$font-pn-regular: 'Proxima Nova Rg', sans-serif;
$font-pn-semibold: 'Proxima Nova Semibold', sans-serif;
$font-pn-bold: 'Proxima Nova Bold', sans-serif;

$trans: all .25s ease;

$blue: #2A83BB;
$orange: #ff9933;
$green: #ABCE3B;
$green_gradient: linear-gradient(to right, rgba(139,176,76,1) 0%, rgba(170,207,59,1) 100%);
$blue_gradient: linear-gradient(to right, rgba(0, 120, 190, 1) 0%, rgba(0, 158, 193, 1) 100%);

html, body {
  font-family: $font-pn-regular;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}

a {
  outline: none;
}

.privacy_anchor {
  color: #333;
  text-decoration: underline;
  transition: $trans;

  &:hover {
    color: $blue;
    text-decoration: none;
  }
}

.blue_btn {
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none !important;
  background: rgba(0, 120, 190, 1);
  background: linear-gradient(to right, rgba(0, 120, 190, 1) 0%, rgba(0, 158, 193, 1) 100%);
  //box-shadow: 0 10px 25px 0 rgba(51, 102, 204, 0.25);
  text-align: center;
  transition: $trans;
  cursor: pointer !important;

  &:hover {
    color: #ffffff;
    background: linear-gradient(to right, rgba(0, 158, 193, 1) 0%, rgba(0, 120, 190, 1) 100%);
  }
}

.img_bg {
  position: absolute;
}

.checkbox_wrap {

  .ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-radius: 0;
  }

  .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    color: #333;
    font-size: 14px;
    border: none !important;
    background: none;
    outline: none !important;
    box-shadow: none !important;
  }

  .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
    border: none;
    background: none;
    outline: none !important;
  }

  .ui-checkboxradio-label .ui-icon-background {
    border: 1px solid $blue;
    background-color: #fff;
    background-image: none;
  }

  .ui-state-active .ui-icon, .ui-button:active .ui-icon {
    background: url("../img/icons/check_checkbox.png") no-repeat center top 2px, linear-gradient(to right, rgba(0, 120, 190, 1) 0%, rgba(0, 158, 193, 1) 100%);
    box-shadow: none;
  }

  .ui-icon {
    height: 14px;
    width: 14px;
  }

  .ui-checkboxradio-icon-space {
    display: none;
  }

  .checkbox_desc {
    padding-left: 6px;
    font-size: 13px;
    display: inline-block;
    max-width: 225px;
  }

  .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
    position: relative;
    top: -18px;
  }
}

  input[type='tel'].phone_input  {
    height: 36px;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 26px;
    padding-right: 10px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid $blue;
    color: $blue !important;
    outline: none;
    background: transparent url("../img/icons/input_phone.png") no-repeat left center;

    &::-webkit-input-placeholder {
      color: $blue;
    }
    &::-moz-placeholder {
      color: $blue;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $blue;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $blue;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent
    }
    &:focus::-moz-placeholder {
      color: transparent
    }
    &:focus:-moz-placeholder {
      color: transparent
    }
    &:focus:-ms-input-placeholder {
      color: transparent
    }
  }

  //HEADER SECTION

.header_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
  position: relative;

    span {
      position: absolute;
      display: block;
      top: 9px;
      left: -87px;
      line-height: 33px;
      width: 35px;
      text-align: center;
      font-family: $font-pn-bold;
      font-size: 16px;
      color: #828282;
      border: 1px solid #e5e5e5;
      border-radius: 50%;

      &:before {
        content: '';
        top: -98px;
        left: 16px;
        position: absolute;
        display: block;
        height: 98px;
        width: 1px;
        background-color: #e5e5e5;
      }

      &:after {
        content: '';
        top: 34px;
        left: 16px;
        position: absolute;
        display: block;
        height: 2000px;
        width: 1px;
        background-color: #e5e5e5;
      }
    }

  h2 {
    display: inline-block;
    margin-bottom: 0;
    font-family: $font-pn-bold;
    font-size: 48px;
    line-height: 56px;
  }

  h5 {
    display: inline-block;
    margin-bottom: 0;
    font-size: 10px;
    text-transform: uppercase;
  }

  .header_line {
    height: 1px;
    background-color: #e5e5e5;
  }
}

//HEADER

header {
  position: fixed;
  z-index: 100;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: #fff;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-left: 23px;
    list-style: none;

    li {
      margin-right: 42px;

      a {
        font-size: 16px;
        color: #333;
        border-bottom: 2px solid transparent;
        transition: $trans;
        text-decoration: none !important;

        &:hover {
          color: $blue;
          border-bottom: 2px solid $blue;
        }
      }
    }
  }
}

.header_row {
  align-items: center;
}

.main_logo {
  display: block;
  padding-left: 68px;
  font-family: $font-pn-semibold;
  font-size: 16px;
  line-height: 22px;
  color: $blue;
  text-transform: uppercase;
  text-shadow: 0 10px 25px rgba(51, 102, 204, 0.25);
  background: url("../img/icons/main_logo.png") no-repeat center left;
  text-decoration: none !important;
  transition: $trans;

  &:hover {
    color: lighten($blue, 10%);
  }
}

.header_phone_wrap {
  max-width: 215px;
  margin-left: auto;

  span {
    padding-left: 26px;
    color: $blue;
  }

  p {
    margin-top: 2px;
    margin-bottom: 4px;
    padding-left: 26px;
    font-size: 18px;
    font-family: $font-pn-bold;
    background: url("../img/icons/header_phone.png") no-repeat left center;
    letter-spacing: 1.2px;
  }
}

//MAIN SCREEN

.main_screen {
  padding-top: 100px;
  background: url("../img/main_screen_bg.jpg") no-repeat center top 100px;
  background-size: cover;

  &>.container {
    position: relative;
    padding-top: 66px;
    padding-bottom: 100px;
  }

  .col_left {
    padding-top: 52px;
    padding-right: 65px;
  }

  .photo_name {
    display: flex;
    align-items: center;
    height: 62px;
    max-width: 244px;
    margin-bottom: 24px;

    img {
      border-radius: 50%;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    }

    span {
      margin-left: 15px;
      line-height: 22px;
      color: $orange;
    }
  }

  h1 {
    margin-bottom: 23px;
    font-family: $font-pn-bold;
    font-size: 36px;
    line-height: 46px;

    span {
      color: $orange;
    }
  }

  h3 {
    margin-bottom: 32px;
    font-size: 22px;
  }

  .blue_btn {
    max-width: 250px;
    width: 100%;
  }

  form {
    max-width: 360px;
    margin-left: auto;
    margin-right: 30px;
    border-left: 10px solid #8BB04C;
    border-right: 10px solid #ABCF3B;

    .in_form {
      padding-top: 34px;
      padding-bottom: 31px;
      padding-left: 44px;
      padding-right: 44px;
    }

    &:before {
      content: '';
      display: block;
      height: 10px;
      width: 100%;
      background: linear-gradient(to right, rgba(139,176,76,1) 0%, rgba(170,207,59,1) 100%);
    }

    &:after {
      content: '';
      display: block;
      height: 10px;
      width: 100%;
      background: linear-gradient(to right, rgba(139,176,76,1) 0%, rgba(170,207,59,1) 100%);
    }

    h4 {
      margin-bottom: 20px;
      font-family: $font-pn-bold;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    .item {
      position: relative;
      margin-bottom: 20px;

      &:before {
        position: absolute;
        display: block;
        width: 20px;
        left: -29px;
        font-family: $font-pn-bold;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        background: rgba(120, 177, 66, 1);
        background: linear-gradient(to right, rgba(120, 177, 66, 1) 0%, rgba(155, 207, 33, 1) 100%);
      }

      &:nth-of-type(1):before {
        content: '1';
      }

      &:nth-of-type(2):before {
        content: '2';
      }

      &:nth-of-type(3):before {
        content: '3';
      }

      a {
        color: $blue;
        border-bottom: 1px solid transparent;
        transition: $trans;

        &:hover {
          border-bottom: 1px solid $blue;
          text-decoration: none;
        }
      }

      span {
        color: $blue;
        text-transform: uppercase;
      }
    }

    input[type='tel'] {
      height: 36px;
      width: 100%;
      margin-bottom: 30px;
      padding-left: 26px;
      padding-right: 10px;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 2px solid $blue;
      color: $blue !important;
      outline: none;
      background: transparent url("../img/icons/input_phone.png") no-repeat left center;

      &::-webkit-input-placeholder {
        color: $blue;
      }
      &::-moz-placeholder {
        color: $blue;
      }
      /* Firefox 19+ */
      &:-moz-placeholder {
        color: $blue;
      }
      /* Firefox 18- */
      &:-ms-input-placeholder {
        color: $blue;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent
      }
      &:focus::-moz-placeholder {
        color: transparent
      }
      &:focus:-moz-placeholder {
        color: transparent
      }
      &:focus:-ms-input-placeholder {
        color: transparent
      }
    }

    .blue_btn {
      max-width: 100%;
      width: 100%;
      margin-bottom: 32px;
      border: none;
      outline: none !important;
      letter-spacing: 1.5px;
    }

    .checkbox_desc {
      padding-left: 6px;
      font-size: 13px;
      display: inline-block;
      max-width: 225px;
    }

    .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
      position: relative;
      top: -18px;
    }
  }

  .left_decoration {
    position: absolute;
    top: 236px;
    left: -231px;
    transform: rotate(-90deg);

    span {
      position: relative;
      font-size: 10px;
      text-transform: uppercase;

      &:first-of-type:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: -357px;
        height: 1px;
        width: 346px;
        background-color: #e5e5e5;
      }

      &:first-of-type:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        right: -57px;
        height: 1px;
        width: 46px;
        background-color: #e5e5e5;
      }

      &:last-of-type:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: -114px;
        height: 1px;
        width: 102px;
        background-color: #e5e5e5;
      }

      &:last-of-type:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        right: -77px;
        height: 1px;
        width: 66px;
        background-color: #e5e5e5;
      }
    }

    img {
      position: relative;
      margin-right: 110px;
      margin-left: 54px;
      transform: rotate(90deg);
    }
  }
}

//POPAP FORM

.popap-form {
  position: relative;
  max-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 33px;
  padding-left: 56px;
  padding-right: 56px;
  padding-bottom: 42px;
  background-color: #fff;

  .mfp-close {
    color: $blue;
  }

  h4 {
    margin-bottom: 18px;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  p {
    margin-bottom: 18px;
    text-align: center;
  }

  .blue_btn {
    width: 100%;
    margin-bottom: 15px;
    border: none;
  }

  .checkbox_wrap {
    max-width: 246px;
    margin-left: auto;
    margin-right: auto;
  }
}

//SERVICES

.services {
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 88px;
    padding-bottom: 56px;

    .img_bg:first-of-type {
      height: 140px;
      top: 210px;
      left: -249px;
    }

    .img_bg:nth-of-type(2) {
      height: 222px;
      top: 1030px;
      left: -446px;
    }

    .img_bg:nth-of-type(3) {
      top: 516px;
      right: -523px;
    }

    .img_bg:nth-of-type(4) {
      top: 474px;
      left: -245px;
      transform: rotate(30deg);
    }

    .img_bg:nth-of-type(5) {
      height: 47px;
      top: 1212px;
      left: -187px;
      transform: rotate(71deg);
    }

    .img_bg:nth-of-type(6) {
      top: 165px;
      right: -220px;
    }

    .img_bg:nth-of-type(7) {
      height: 50px;
      top: 750px;
      right: -231px;
    }

    .img_bg:nth-of-type(8) {
      top: 1095px;
      right: -187px;
      transform: rotate(37deg);
    }

    .img_bg:nth-of-type(9) {
      top: 55px;
      left: -36px;
    }
  }
}

.service_col:nth-of-type(9) {

  .img_wrap {
    flex-direction: column;

    img:first-of-type {
      margin-bottom: 15px;
    }

    img:nth-of-type(2) {
      max-width: 150px;
    }
  }
}

.service_col:nth-of-type(3n+1){

  .service_item {
    margin-right: auto;
  }
}

.service_col:nth-of-type(3n+2){

  .service_item {
    margin-left: auto;
    margin-right: auto;
  }
}

.service_col:nth-of-type(3n+3){

  .service_item {
    margin-left: auto;
  }
}

.service_item {
  margin-bottom: 54px;
  max-width: 292px;
  //padding-top: 40px;
  //padding-bottom: 40px;
  text-align: center;
  transition: $trans;

  &>a {
    display: block;
    height: 100%;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-decoration: none !important;
    color: #333;
  }

  &:hover {
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
  }

  h3 {
    position: relative;
    z-index: 10;
    margin-bottom: 0;
    font-size: 22px;
    line-height: 10px;
  }

  .img_wrap {
    display: flex;
    height: 113px;
    align-items: center;
    justify-content: center;
  }

  .price {
    margin-bottom: 40px;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 12px;
    color: $green;
  }

  .blue_btn {
    width: 177px;
  }
}

//ORDER CONSULTATION

.order_consultation {
  padding-top: 69px;
  padding-bottom: 141px;
  background: url("../img/order_consultation1.jpg") no-repeat center;
  background-size: cover;

  &:nth-of-type(2){
    background: url("../img/order_consultation2.jpg") no-repeat center;
    background-size: cover;
  }

  &:nth-of-type(4){
    background: url("../img/order_consultation3.jpg") no-repeat center;
    background-size: cover;

    h2 {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:nth-of-type(6){
    background: url("../img/order_consultation4.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(8){
    background: url("../img/order_consultation5.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(10){
    background: url("../img/order_consultation6.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(12){
    background: url("../img/order_consultation7.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(14){
    background: url("../img/order_consultation8.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(16){
    background: url("../img/order_consultation9.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(18){
    background: url("../img/order_consultation10.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(20){
    padding-top: 70px;
    background: url("../img/order_consultation11.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 22px;
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  &:nth-of-type(22){
    background: url("../img/order_consultation12.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(24){
    background: url("../img/order_consultation13.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(28){
    background: url("../img/order_consultation14.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  &:nth-of-type(29){
    background: url("../img/order_consultation15.jpg") no-repeat center;
    background-size: cover;

    h2 {
      margin-bottom: 79px;
    }
  }

  h2 {
    margin-bottom: 26px;
    font-family: $font-pn-bold;
    font-size: 48px;
    text-align: center;
  }

  p {
    margin-bottom: 33px;
    font-size: 22px;
    text-align: center;
  }

  input[type='text'].phone_input {
    margin-bottom: 40px;
  }

  form {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .blue_btn {
    width: 100%;
    margin-bottom: 15px;
    border: 0;
    outline: none;
  }
}

//OUR CLIENTS

.our_clients {
  background-color: #F7F8F9;
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 130px;

    .img_bg:first-of-type {
      height: 150px;
      top: 364px;
      right: -443px;
    }

    .img_bg:nth-last-of-type(1) {
      height: 40px;
      top: 51px;
      left: -389px;
      transform: rotate(53deg);
    }

    .img_bg:nth-last-of-type(2) {
      height: 40px;
      top: 51px;
      left: -30px;
      transform: rotate(46deg);
    }

    .img_bg:nth-last-of-type(3) {
      top: 343px;
      left: -325px;
      transform: rotate(14deg);
    }

    .img_bg:nth-last-of-type(4) {
      height: 45px;
      top: 143px;
      right: -325px;
      transform: rotate(14deg);
    }

    .img_bg:nth-last-of-type(5) {
      top: 343px;
      right: -219px;
    }
  }

  .header_section h2 {
    position: relative;
    top: 28px;
    max-width: 375px;
  }

  .header_section span {
    top: 35px;
  }

  .client_wrap {
    position: relative;
    max-width: 194px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 28px;
    text-align: center;

    .img_wrap {
      height: 105px;
    }

    .num_item {
      position: absolute;
      top: 0;
      left: 10px;
      width: 35px;
      font-family: $font-pn-bold;
      font-size: 16px;
      line-height: 35px;
      color: #ffffff;
      background: $green_gradient;
      text-align: center;
      border-radius: 50%;
    }

    p {
      font-family: $font-pn-semibold;
      font-size: 16px;
    }
  }
}

//ADVANTAGES YANDEX DIRECT

.advantages_yandex {
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 90px;

    .img_bg:first-of-type {
      top: 300px;
      left: -405px;
      transform: rotate(-30deg);
    }

    .img_bg:nth-of-type(2) {
      height: 40px;
      top: 480px;
      left: -185px;
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 641px;
      left: -313px;
      transform: rotate(-67deg);
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 292px;
      right: -259px;
    }

    .img_bg:nth-of-type(5) {
      top: 402px;
      right: -379px;
      transform: rotate(-36deg);
    }

    .img_bg:nth-of-type(6) {
      height: 40px;
      top: 631px;
      right: -249px;
      transform: rotate(40deg);
    }
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 600px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }
}

.advantages_wrap {
  position: relative;
  max-width: 244px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #dadada;
  border-radius: 5px;
  text-align: center;

  .img_wrap {
    height: 109px;
  }

  p {
    position: relative;
    top: -9px;
    font-size: 16px;
    line-height: 22px;
  }
  
  span {
    position: absolute;
    display: block;
    left: -7px;
    top: 66px;
    line-height: 47px;
    font-family: $font-pn-bold;
    font-size: 50px;
    color: $green;
    background-color: #fff;
  }
}

//ADVERTISING PLATFORMS

.advertising_platforms {
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 132px;

    .img_bg:first-of-type {
      top: 99px;
      left: -479px;
    }

    .img_bg:nth-of-type(2) {
      top: 303px;
      left: -273px;
      transform: rotate(44deg);
    }

    .img_bg:nth-of-type(3) {
      height: 222px;
      top: 933px;
      left: -317px;
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 989px;
      left: -251px;
      transform: rotate(44deg);
    }

    .img_bg:nth-of-type(5) {
      height: 40px;
      top: 1565px;
      left: -146px;
      transform: rotate(15deg);
    }

    .img_bg:nth-of-type(6) {
      top: 2070px;
      left: -232px;
    }

    .img_bg:nth-of-type(7) {
      height: 40px;
      top: 2288px;
      left: -232px;
      transform: rotate(62deg);
    }

    .img_bg:nth-of-type(8) {
      top: 56px;
      right: -165px;
    }

    .img_bg:nth-of-type(9) {
      height: 40px;
      top: 422px;
      right: -350px;
    }

    .img_bg:nth-of-type(10) {
      top: 822px;
      right: -234px;
      transform: rotate(30deg);
    }

    .img_bg:nth-of-type(11) {
      height: 150px;
      top: 1173px;
      right: -267px;
    }

    .img_bg:nth-of-type(12) {
      height: 40px;
      top: 1378px;
      right: -190px;
      transform: rotate(30deg);
    }

    .img_bg:nth-of-type(13) {
      top: 1902px;
      right: -236px;
      transform: rotate(10deg);
    }

    .img_bg:nth-of-type(14) {
      height: 150px;
      top: 2300px;
      right: -176px;
    }

    .img_bg:nth-of-type(15) {
      height: 40px;
      top: 2448px;
      right: -299px;
      transform: rotate(46deg);
    }

    .img_bg:nth-of-type(16) {
      top: 60px;
      left: -34px;
    }
  }

  .header_section {
    margin-bottom: 91px;
  }

  .header_section h2 {
    max-width: 456px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  .tablets_row {

    &>div {
      margin-bottom: 73px;
    }

    &>div:nth-of-type(odd) {
      p {
        position: relative;
        margin-bottom: 27px;
        padding-left: 68px;
        font-size: 18px;

        &:before {
          content: '';
          position: absolute;
          display: block;
          height: 20px;
          width: 20px;
          left: 28px;
          background: $blue_gradient;
          border-radius: 50%;
          box-shadow: 0 10px 25px 0 rgba(51,102,204,0.25);
        }
      }

      img {
        position: relative;
        left: -15px;
      }
    }

    &>div:nth-of-type(even) {

      p {
        position: relative;
        margin-bottom: 27px;
        padding-left: 104px;
        font-size: 18px;

        &:before {
          content: '';
          position: absolute;
          display: block;
          height: 20px;
          width: 20px;
          left: 39px;
          background: $blue_gradient;
          border-radius: 50%;
          box-shadow: 0 10px 25px 0 rgba(51,102,204,0.25);
        }
      }

      img {
        position: relative;
        right: -20px;
      }
    }
  }

  .yandex_laptop_row {

    &>div:first-of-type {
      position: relative;

      img:first-of-type {
        position: absolute;
        z-index: 10;
        left: -51px;
      }

      img:last-of-type {
        position: absolute;
        z-index: 0;
        top: 14px;
        right: 0;
      }
    }

    &>div:nth-of-type(2) {
      padding-top: 41px;
    }

    h3 {
      margin-bottom: 39px;
      font-family: $font-pn-bold;
      font-size: 32px;
      line-height: 42px;
      color: $green;
    }

    .question {
      position: relative;
      max-width: 490px;
      padding-top: 52px;
      padding-bottom: 49px;
      padding-left: 49px;
      padding-right: 30px;
      font-size: 18px;
      line-height: 24px;
      border: 3px solid $green;
      border-radius: 4px;

      span {
        display: block;
        position: absolute;
        top: 63px;
        left: -12px;
        font-family: $font-pn-bold;
        font-size: 50px;
        line-height: 49px;
        color: $green;
        background-color: #fff;
      }
    }
  }

  .banners_row {

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.advertising_platforms.google_adwords_advertisting .tablets_row > div:nth-of-type(odd) p {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

//TARIFF PRICES

.mobile_tariff {
  display: none;
}

.tariff_prices {
  background-color: #F7F8F9;
  overflow: hidden;

  .header_section span:after {
    height: 2200px;
  }


  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 158px;

    .img_bg:first-of-type {
      height: 40px;
      top: 182px;
      left: -192px;
    }

    .img_bg:nth-of-type(2) {
      top: 716px;
      left: -411px;
      transform: rotate(22deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 1150px;
      left: -259px;
      transform: rotate(56deg);
    }

    .img_bg:nth-of-type(4) {
      top: 1479px;
      left: -211px;
      transform: rotate(102deg);
    }

    .img_bg:nth-of-type(5) {
      height: 40px;
      top: 1924px;
      left: -349px;
      transform: rotate(59deg);
    }

    .img_bg:nth-of-type(6) {
      top: 110px;
      right: -398px;
    }

    .img_bg:nth-of-type(7) {
      height: 40px;
      top: 438px;
      right: -234px;
      transform: rotate(28deg);
    }

    .img_bg:nth-of-type(8) {
      top: 983px;
      right: -182px;
      transform: rotate(42deg);
    }

    .img_bg:nth-of-type(9) {
      height: 40px;
      top: 1332px;
      right: -301px;
    }

    .img_bg:nth-of-type(10) {
      height: 40px;
      top: 1879px;
      right: -149px;
      transform: rotate(79deg);
    }

    .img_bg:nth-of-type(11) {
      top: 60px;
      left: -22px;
    }
  }

  .header_section {
    margin-bottom: 96px;

    a {
      position: absolute;
      top: 109px;
      right: 0;
      color: $blue;
      border-bottom: 1px dashed $blue !important;
      text-decoration: none !important;
      border-bottom: 1px solid transparent;
      transition: $trans;
      cursor: pointer;

      &:hover {
        color: $green;
        border-bottom: none !important;
      }
    }
  }

  .header_section h2 {
    max-width: 498px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  .header_table_row {

    div {
      line-height: 60px;
      color: #fff;
      text-transform: uppercase;
      background-color: $green;
      text-align: center;
      border-left: 1px solid #fff;
      
      &:nth-of-type(1){
        background-color: #fff;
        color: #333;
      }
    }
  }

  .body_table_row {

    &:nth-of-type(even) {
      background-color: #fff;
    }

    &:last-of-type {
      background-color: transparent;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      line-height: 18px;
      text-align: center;
    }

    .blue_btn {
      height: 68px;
      max-width: 140px;
      width: 100%;
      margin-top: 35px;
    }
  }

  .tariff_question_row {
    padding-top: 93px;

    .question {
      margin-left: 82px;
      position: relative;
      max-width: 490px;
      padding-top: 44px;
      padding-bottom: 43px;
      padding-left: 49px;
      padding-right: 30px;
      font-size: 18px;
      line-height: 24px;
      border: 3px solid $green;
      border-radius: 4px;

      span {
        display: block;
        position: absolute;
        top: 44px;
        left: -12px;
        font-family: $font-pn-bold;
        font-size: 50px;
        line-height: 49px;
        color: $green;
        background-color: #F7F8F9;
      }
    }

    ul {
      margin-left: 66px;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 70px;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 20px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          height: 10px;
          width: 10px;
          left: -20px;
          top: 5px;
          border-radius: 50%;
          background-color: $green;
        }
      }
    }
  }
}

//SPACIAK RATE

.special_rate {
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 158px;

    .img_bg:first-of-type {
      height: 40px;
      top: 40px;
      left: -269px;
      transform: rotate(43deg);
    }

    .img_bg:nth-of-type(2) {
      height: 40px;
      top: 237px;
      left: -387px;
      transform: rotate(-30deg);
    }

    .img_bg:nth-of-type(3) {
      top: 457px;
      left: -229px;
      transform: rotate(10deg);
    }

    .img_bg:nth-of-type(4) {
      height: 150px;
      top: 653px;
      left: -336px;
    }

    .img_bg:nth-of-type(5) {
      height: 40px;
      top: 341px;
      right: -241px;
      transform: rotate(10deg);
    }

    .img_bg:nth-of-type(6) {
      top: 651px;
      right: -250px;
      transform: rotate(-34deg);
    }
  }

  .header_section {
    margin-bottom: 89px;
  }

  .header_section h2 {
    max-width: 586px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  h3 {
    margin-bottom: 57px;
    font-size: 32px;
    color: $green;
    text-align: center;

    b {
      font-family: $font-pn-bold;
    }
  }

  .rate_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;

    .rate_wrap {
      max-width: 268px;
      position: relative;
      display: flex;
      justify-content: center;

      &:nth-of-type(2) {
        position: relative;
        margin-left: 91px;
        margin-right: 91px;

        &:before {
          content: url("../img/icons/rate_plus.png");
          position: absolute;
          left: -76px;
          top: 48px;
        }

        &:after {
          content: url("../img/icons/rate_plus.png");
          position: absolute;
          right: -76px;
          top: 48px;
        }
      }

      p {
        position: absolute;
        display: block;
        max-width: 165px;
        top: 45px;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
      }
    }
  }

  .sum {
    margin-bottom: 68px;
    font-family: $font-pn-bold;
    font-size: 56px;
    color: #FF8D03;
    text-align: center;
  }

  .blue_btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 223px;
  }
}

//ADVERTISING BUDGET

.advertising_budget {
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 158px;

    .img_bg:first-of-type {
      top: 186px;
      left: -414px;
      transform: rotate(-29deg);
    }

    .img_bg:nth-of-type(2) {
      height: 40px;
      top: 390px;
      left: -189px;
      transform: rotate(-7deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 490px;
      left: -323px;
      transform: rotate(50deg);
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 206px;
      right: -260px;
      transform: rotate(-16deg);
    }

    .img_bg:nth-of-type(5) {
      top: 326px;
      right: -371px;
      transform: rotate(-46deg);
    }

    .img_bg:nth-of-type(6) {
      height: 40px;
      top: 541px;
      right: -207px;
      transform: rotate(-46deg);
    }
  }

  .header_section {
    margin-bottom: 129px;
  }

  .header_section h2 {
    max-width: 586px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  .budget_wrap {
    position: relative;
    z-index: 10;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    box-shadow: 0 15px 30px 0 rgba(51,102,204,0.1);
    background-color: #fff;

    .budget {
      position: relative;
      height: 100%;
      width: 100%;
      padding-top: 36px;
      padding-bottom: 27px;
      padding-left: 36px;
      padding-right: 36px;
      background-color: #fff;
      z-index: 10;
    }

    &:after {
      content: url("../img/budget_corner.jpg");
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &.left_budget:before {
      content: '>200 000';
      position: absolute;
      top: -24px;
      left: 90px;
      font-family: $font-pn-bold;
      font-size: 64px;
      color: rgba(51,153,204,.2);
      z-index: 0;
    }

    &.right_budget:before {
      content: '<200 000';
      position: absolute;
      top: -24px;
      left: 90px;
      font-family: $font-pn-bold;
      font-size: 64px;
      color: rgba(51,153,204,.2);
      z-index: 0;
    }
  }

  h6 {
    margin-bottom: 17px;
    font-family: $font-pn-bold;
    font-size: 18px;
    text-align: center;
  }

  h4 {
    margin-bottom: 19px;
    font-family: $font-pn-bold;
    font-size: 24px;
    color: $blue;
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .budget_wrap_bottom {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 1046px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 85px;
    background-color: #fff;
    box-shadow: 0 15px 30px 0 rgba(51,102,204,0.1);

    &:after {
      content: url("../img/budget_corner2.jpg");
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
    }

    p {
      max-width: 378px;
      margin-right: 100px;
      font-size: 18px;
      line-height: 24px;
      color: $green;
      text-align: left;
    }

    .budget_bonus {
      position: relative;
      margin-right: 150px;
      font-family: $font-pn-bold;
      font-size: 28px;
      line-height: 34px;
      color: $orange;

      &:after {
        position: absolute;
        content: url("../img/icons/budget_arrow.png");
        top: 22px;
        right: -88px;
      }
    }

    .blue_btn {
      max-width: 170px;
      width: 100%;
    }
  }
}

//INITIAL RATE

.initial_rate {
  background-color: #F7F8F9;
  overflow: hidden;

  .header_section span:after {
    height: 2200px;
  }

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 136px;

    .img_bg:first-of-type {
      height: 40px;
      top: 94px;
      left: -191px;
      transform: rotate(-10deg);
    }

    .img_bg:nth-of-type(2) {
      top: 640px;
      left: -418px;
      transform: rotate(28deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 1068px;
      left: -255px;
      transform: rotate(59deg);
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 1402px;
      left: -308px;
      transform: rotate(-39deg);
    }

    .img_bg:nth-of-type(5) {
      top: 31px;
      right: -414px;
      transform: rotate(-25deg);
    }

    .img_bg:nth-of-type(6) {
      height: 40px;
      top: 360px;
      right: -301px;
      transform: rotate(-20deg);
    }

    .img_bg:nth-of-type(7) {
      top: 830px;
      right: -325px;
      transform: rotate(31deg);
    }

    .img_bg:nth-of-type(8) {
      height: 40px;
      top: 1362px;
      right: -162px;
      transform: rotate(25deg);
    }
  }

  .header_section {
    margin-bottom: 96px;

    a {
      position: absolute;
      top: 109px;
      right: 0;
      color: $blue;
      border-bottom: 1px dashed $blue !important;
      text-decoration: none !important;
      border-bottom: 1px solid transparent;
      transition: $trans;
      cursor: pointer;

      &:hover {
        color: $green;
        border-bottom: none !important;
      }
    }
  }

  .header_section h2 {
    max-width: 811px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  .header_table_row {

    div {
      line-height: 60px;
      text-transform: uppercase;
      background-color: #fff;
      text-align: center;
      border-left: 1px solid #fff;

      &:nth-of-type(1){
        background-color: #fff;
        color: #333;
      }
    }
  }
  
  .white_row {
    background-color: #fff;
  }

  .body_table_row {

    &:nth-of-type(even) {
      background-color: #fff;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 68px;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 18px;
      text-align: center;
    }

    .blue_btn {
      height: 68px;
      max-width: 140px;
      width: 100%;
      margin-top: 35px;
      letter-spacing: 1.2px;
    }
  }

  .blue_row {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 48px;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    background-color: #6FB8D0;
  }

  h3 {
    margin-top: 44px;
    font-family: $font-pn-bold;
    font-size: 32px;
    color: $orange;
    text-align: center;
    text-shadow: 0 10px 25px rgba(255,153,51,0.25);
  }
}

//RETARGETING

.retargeting {
  overflow: hidden;

  .header_section span:after {
    height: 2200px;
  }

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 150px;

    .img_bg:first-of-type {
      height: 40px;
      top: 55px;
      left: -29px;
      transform: rotate(30deg);
    }

    .img_bg:nth-of-type(2) {
      height: 40px;
      top: 160px;
      left: -385px;
      transform: rotate(40deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 438px;
      left: -285px;
      transform: rotate(-10deg);
    }

    .img_bg:nth-of-type(4) {
      top: 747px;
      left: -285px;
      transform: rotate(38deg);
    }

    .img_bg:nth-of-type(5) {
      height: 40px;
      top: 54px;
      right: -355px;
      transform: rotate(54deg);
    }

    .img_bg:nth-of-type(6) {
      height: 150px;
      top: 190px;
      right: -301px;
    }

    .img_bg:nth-of-type(7) {
      height: 40px;
      top: 478px;
      right: -335px;
      transform: rotate(-7deg);
    }

    .img_bg:nth-of-type(8) {
      top: 701px;
      right: -260px;
      transform: rotate(-31deg);
    }
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 646px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  ul {
    max-width: 667px;
    margin-bottom: 44px;
    padding-left: 23px;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 20px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 10px;
        width: 10px;
        left: -20px;
        top: 5px;
        border-radius: 50%;
        background-color: $green;
      }
    }
  }

  .header_table_row {
    display: flex;

    .table_col {
      width: 20%;
      font-family: $font-pn-semibold;
      font-size: 18px;
      line-height: 88px;
      color: #fff;
      background: $green;
      text-align: center;
      border-right: 3px solid #fff;
      box-shadow: 0 10px 25px 0 rgba(153,204,51,0.25);

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        border-right: 0;
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .body_table_row {
    display: flex;

    .table_col {
      width: 20%;
      padding-top: 26px;
      text-align: center;
    }
  }

  h3 {
    margin-top: 69px;
    margin-bottom: 30px;
    font-family: $font-pn-bold;
    font-size: 32px;
    color: $orange;
    text-align: center;
    text-shadow: 0 10px 25px rgba(255,153,51,0.25);
  }

  .blue_btn {
    display: block;
    width: 224px;
    margin-left: auto;
    margin-right: auto;
  }
}

//YANDEX AUDITORY

.yandex_auditory {
  overflow: hidden;

  .header_section span:after {
    height: 2200px;
  }

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 150px;

    .img_bg:first-of-type {
      height: 40px;
      top: 94px;
      left: -358px;
      transform: rotate(56deg);
    }

    .img_bg:nth-of-type(2) {
      top: 394px;
      left: -272px;
      transform: rotate(13deg);
    }

    .img_bg:nth-of-type(3) {
      top: 640px;
      left: -483px;
    }

    .img_bg:nth-of-type(4) {
      top: 79px;
      right: -239px;
      transform: rotate(26deg);
    }

    .img_bg:nth-of-type(5) {
      top: 487px;
      right: -290px;
      transform: rotate(-7deg);
    }

    .img_bg:nth-of-type(6) {
      height: 150px;
      top: 780px;
      right: -195px;
    }

    .img_bg:nth-of-type(7) {
      height: 40px;
      top: 922px;
      right: -330px;
      transform: rotate(-7deg);
    }

    .img_bg:nth-of-type(8) {
      top: 61px;
      left: -34px;
    }
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 463px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  form {
    max-width: 360px;
    border-left: 10px solid #8BB04C;
    border-right: 10px solid #ABCF3B;

    .in_form {
      padding-top: 34px;
      padding-bottom: 31px;
      padding-left: 44px;
      padding-right: 44px;
    }

    &:before {
      content: '';
      display: block;
      height: 10px;
      width: 100%;
      background: linear-gradient(to right, rgba(139,176,76,1) 0%, rgba(170,207,59,1) 100%);
    }

    &:after {
      content: '';
      display: block;
      height: 10px;
      width: 100%;
      background: linear-gradient(to right, rgba(139,176,76,1) 0%, rgba(170,207,59,1) 100%);
    }

    h4 {
      margin-bottom: 20px;
      font-family: $font-pn-bold;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    .item {
      position: relative;
      margin-bottom: 20px;

      &:before {
        position: absolute;
        display: block;
        width: 20px;
        left: -29px;
        font-family: $font-pn-bold;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        background: rgba(120, 177, 66, 1);
        background: linear-gradient(to right, rgba(120, 177, 66, 1) 0%, rgba(155, 207, 33, 1) 100%);
      }

      &:nth-of-type(1):before {
        content: '1';
      }

      &:nth-of-type(2):before {
        content: '2';
      }

      &:nth-of-type(3):before {
        content: '3';
      }

      span {
        color: $blue;
        text-transform: uppercase;
      }
    }

    input[type='text'] {
      height: 36px;
      width: 100%;
      margin-bottom: 30px;
      padding-left: 26px;
      padding-right: 10px;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 2px solid $blue;
      color: $blue !important;
      outline: none;
      background: transparent url("../img/icons/input_phone.png") no-repeat left center;

      &::-webkit-input-placeholder {
        color: $blue;
      }
      &::-moz-placeholder {
        color: $blue;
      }
      /* Firefox 19+ */
      &:-moz-placeholder {
        color: $blue;
      }
      /* Firefox 18- */
      &:-ms-input-placeholder {
        color: $blue;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent
      }
      &:focus::-moz-placeholder {
        color: transparent
      }
      &:focus:-moz-placeholder {
        color: transparent
      }
      &:focus:-ms-input-placeholder {
        color: transparent
      }
    }

    .blue_btn {
      max-width: 100%;
      width: 100%;
      margin-bottom: 32px;
      border: none;
      outline: none !important;
      letter-spacing: 1.5px;
    }

    .checkbox_desc {
      padding-left: 6px;
      font-size: 13px;
      display: inline-block;
      max-width: 225px;
    }

    .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
      position: relative;
      top: -18px;
    }
  }

  .col_right {
    padding-top: 78px;
  }

  .blue_border_block {
    position: relative;
    max-width: 517px;
    margin-left: auto;
    margin-right: auto;
    padding: 46px 46px 26px 46px;
    font-size: 18px;
    line-height: 26px;
    border: 3px solid $blue;
    border-radius: 4px;
    text-align: center;

    .img_wrap {
      position: absolute;
      top: -30px;
      left: 205px;
      width: 100px;
      background-color: #fff;
    }
  }

  h3 {
    margin-top: 75px;
    margin-bottom: 30px;
    font-family: $font-pn-bold;
    font-size: 32px;
    color: $orange;
    text-align: center;
    text-shadow: 0 10px 25px rgba(255,153,51,0.25);
  }

  .blue_btn {
    display: block;
    width: 224px;
    margin-left: auto;
    margin-right: auto;
  }
}

//ADVANTAGES

.advantages {
  overflow: hidden;

  .header_section span:after {
    height: 2700px;
  }

  &>.container {
    position: relative;
    padding-top: 88px;
    padding-bottom: 150px;

    .img_bg:first-of-type {
      height: 40px;
      top: 46px;
      left: -150px;
      transform: rotate(-7deg);
    }

    .img_bg:nth-of-type(2) {
      height: 150px;
      top: 337px;
      left: -287px;
    }

    .img_bg:nth-of-type(3) {
      top: 607px;
      left: -255px;
      transform: rotate(32deg);
    }

    .img_bg:nth-of-type(4) {
      height: 222px;
      top: 1186px;
      left: -445px;
    }

    .img_bg:nth-of-type(5) {
      height: 40px;
      top: 1363px;
      left: -175px;
      transform: rotate(32deg);
    }

    .img_bg:nth-of-type(6) {
      top: 1756px;
      left: -175px;
      transform: rotate(-1deg);
    }

    .img_bg:nth-of-type(7) {
      height: 40px;
      top: 2142px;
      left: -150px;
      transform: rotate(34deg);
    }

    .img_bg:nth-of-type(8) {
      height: 40px;
      top: 2342px;
      left: -277px;
      transform: rotate(-25deg);
    }

    .img_bg:nth-of-type(9) {
      top: 310px;
      right: -251px;
      transform: rotate(-27deg);
    }

    .img_bg:nth-of-type(10) {
      top: 658px;
      right: -551px;
    }

    .img_bg:nth-of-type(11) {
      height: 40px;
      top: 898px;
      right: -251px;
    }

    .img_bg:nth-of-type(12) {
      top: 1253px;
      right: -348px;
      transform: rotate(32deg);
    }

    .img_bg:nth-of-type(13) {
      height: 40px;
      top: 1610px;
      right: -148px;
      transform: rotate(52deg);
    }

    .img_bg:nth-of-type(14) {
      top: 1917px;
      right: -300px;
      transform: rotate(-15deg);
    }

    .img_bg:nth-of-type(15) {
      height: 150px;
      top: 2113px;
      right: -300px;
    }
  }
}

.certificetes_row {

  &>div {
    display: flex;
    justify-content: center;

    &:last-of-type {
      display: block;
    }
  }

  img {
    margin-bottom: 50px;
  }

  .blue_btn {
    display: block;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    max-width: 250px;
    width: 100%;
  }
}

.my_advantages_row {
  margin-bottom: 33px;

  h3 {
    margin-bottom: 30px;
    font-family: $font-pn-bold;
    font-size: 32px;
    color: $green;
    text-shadow: 0 10px 25px rgba(153,204,51,0.25);
    text-align: center;
  }
}

.advantage_wrap {
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  padding-top: 16px;
  padding-bottom: 19px;
  box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
  border-radius: 6px;
  text-align: center;

  .img_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 107px;
  }

  p {
    position: relative;
    top: -5px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    line-height: 22px;
  }
}

.guarantee_row {
  text-align: center;

  h3 {
    margin-bottom: 27px;
    font-family: $font-pn-bold;
    font-size: 32px;
  }

  h4 {
    margin-bottom: 37px;
    font-size: 18px;
    color: $green;
  }

  .orange_border_block {
    position: relative;
    margin-top: 11px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 47px;
    padding-bottom: 45px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    border: 3px solid $orange;
    border-radius: 5px;
    max-width: 636px;

    &:after {
      position: absolute;
      top: 30px;
      left: -7px;
      content: "!";
      display: block;
      font-family: $font-pn-bold;
      font-size: 50px;
      line-height: 55px;
      color: $orange;
      background-color: #fff;
    }
  }
}

//WORK SCHEME

.work_scheme {
  overflow: hidden;
  background-color: #F7F8F9;

  .header_section span:after {
    height: 2200px;
  }

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 115px;

    .img_bg:first-of-type {
      top: 221px;
      left: -411px;
      transform: rotate(-19deg);
    }

    .img_bg:nth-of-type(2) {
      height: 40px;
      top: 402px;
      left: -211px;
      transform: rotate(4deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 559px;
      left: -311px;
      transform: rotate(40deg);
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 218px;
      right: -247px;
      transform: rotate(-16deg);
    }

    .img_bg:nth-of-type(5) {
      top: 332px;
      right: -378px;
      transform: rotate(-31deg);
    }

    .img_bg:nth-of-type(6) {
      height: 40px;
      top: 549px;
      right: -226px;
      transform: rotate(-31deg);
    }
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 329px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  .row_steps1 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-left: 32px;

    &:after {
      content: url("../img/steps_right.jpg");
      position: absolute;
      right: 0;
      top: 23px;
    }
  }

  .step {
    position: relative;
    max-width: 225px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 8px;
      top: 23px;
      background-color: #EEF0FF;
      border-radius: 8px;
    }

    &:last-of-type {
      max-width: 250px;
    }

    .top_step {
      display: flex;
      align-items: flex-end;
      margin-bottom: 42px;

      b {
        margin-right: 19px;
        font-family: $font-pn-bold;
        font-size: 64px;
        line-height: 10px;
        color: $green;
      }

      span {
        position: relative;
        top: 2px;
        font-size: 12px;
        line-height: 16px;
        color: #3366cc;
        text-transform: uppercase;
      }
    }

    p {
      font-size: 18px;
      position: relative;
      z-index: 10;
    }
  }

  .step1 {
    margin-right: 100px;

    &:after {
      width: 135px;
      right: -93px;
    }
  }

  .step2 {
    margin-right: 60px;

    &:after {
      width: 124px;
      right: -51px;
    }
  }

  .step3 {
    margin-right: 59px;

    &:after {
      width: 115px;
      left: 158px;
    }
  }

  .step4 {

    &:after {
      width: 76px;
      left: 153px;
    }
  }

  .step5 {
    margin-right: 118px;

    &:after {
      width: 163px;
      left: -178px;
    }
  }

  .step6 {
    max-width: 254px;
    margin-right: 46px;

    &:after {
      width: 115px;
      left: -129px;
    }
  }

  .step7 {

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 8px;
      top: 23px;
      background-color: #EEF0FF;
      border-radius: 8px;
      width: 106px;
      left: -117px;
    }

    &:after {
      width: 102px;
      left: 153px;
    }
  }

  .row_steps2 {
    height: 8px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 96px;
    background-color: #EEF0FF;
    border-radius: 8px;
  }

  .row_steps3 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-left: 206px;

    &:after {
      content: url("../img/steps_left.jpg");
      position: absolute;
      left: 0;
      top: -104px;
    }

    &:before {
      content: url("../img/icons/racing-flag.png");
      position: absolute;
      right: 63px;
      top: 5px;
    }
  }
}

//CASES

.cases {
  overflow: hidden;

  .header_section {
    margin-bottom: 63px;
  }

  .header_section span:after {
    height: 2700px;
  }

  &>.container {
    position: relative;
    padding-top: 88px;
    padding-bottom: 35px;

    .img_bg:first-of-type {
      top: 100px;
      left: -488px;
    }

    .img_bg:nth-of-type(2) {
      height: 40px;
      top: 614px;
      left: -278px;
      transform: rotate(54deg);
    }

    .img_bg:nth-of-type(3) {
      top: 869px;
      left: -251px;
      transform: rotate(34deg);
    }

    .img_bg:nth-of-type(4) {
      top: 42px;
      right: -275px;
      transform: rotate(-2deg);
    }

    .img_bg:nth-of-type(5) {
      height: 150px;
      top: 235px;
      right: -198px;
    }

    .img_bg:nth-of-type(6) {
      height: 40px;
      top: 650px;
      right: -222px;
      transform: rotate(-2deg);
    }

    .img_bg:nth-of-type(7) {
      top: 912px;
      right: -348px;
      transform: rotate(29deg);
    }

    .img_bg:nth-of-type(8) {
      top: 60px;
      left: -36px;
    }
  }

  h4 {
    margin-bottom: 33px;
    font-family: $font-pn-bold;
    font-size: 20px;
  }

  .circle_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-size: 16px;
    }
  }

  .green_circle {
    height: 80px;
    width: 80px;
    margin-left: 16px;
    padding-top: 26px;
    font-size: 14px;
    line-height: 14px;
    color: $green;
    border: 5px solid $green;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 10px 25px 0 rgba(153,204,51,0.25);
    text-shadow: 0 10px 25px rgba(153,204,51,0.25);

    b {
      font-family: $font-pn-bold;
      font-size: 24px;
    }
  }

  .orange_circle {
    height: 80px;
    width: 80px;
    margin-left: 16px;
    padding-top: 26px;
    font-size: 14px;
    line-height: 14px;
    color: $orange;
    border: 5px solid $orange;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 10px 25px 0 rgba(255,153,51,.25);
    text-shadow: 0 10px 25px rgba(255,153,51,0.25);

    b {
      font-family: $font-pn-bold;
      font-size: 24px;
    }
  }

  .case_col {
    margin-bottom: 115px;
  }

  .case_col:nth-of-type(1) {

    img {
      margin-bottom: 50px;
      box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
    }
  }

  .case_col:nth-of-type(4) {

    img {
      margin-bottom: 53px;
      box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
    }
  }

  .case_col:nth-of-type(3n) {

    p {
      margin-bottom: 22px;
    }

    img {
      margin-bottom: 21px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .text_block {
    margin-bottom: 16px;

    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;

      b {
        font-family: $font-pn-semibold;
      }
    }

    &:nth-of-type(3) {
      margin-bottom: 47px;
    }
  }
}

//EXPERIENCE

.experience {
  overflow: hidden;

  .header_section span:after {
    height: 2200px;
  }

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 110px;

    .img_bg:first-of-type {
      height: 40px;
      top: 219px;
      left: -183px;
    }

    .img_bg:nth-of-type(2) {
      top: 654px;
      left: -383px;
      transform: rotate(25deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 1091px;
      left: -203px;
      transform: rotate(61deg);
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 1433px;
      left: -268px;
      transform: rotate(-27deg);
    }

    .img_bg:nth-of-type(5) {
      top: 29px;
      right: -413px;
      transform: rotate(-19deg);
    }

    .img_bg:nth-of-type(6) {
      height: 40px;
      top: 470px;
      right: -255px;
      transform: rotate(-5deg);
    }

    .img_bg:nth-of-type(7) {
      top: 919px;
      right: -262px;
      transform: rotate(34deg);
    }

    .img_bg:nth-of-type(8) {
      height: 40px;
      top: 1380px;
      right: -313px;
      transform: rotate(25deg);
    }
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 779px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  .experience_item {
    display: block;
    min-height: 150px;
    max-width: 244px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    padding-top: 35px;
    padding-bottom: 19px;
    text-align: center;
    border: 1px solid #dadada;
    border-radius: 5px;
    color: #333;
    text-decoration: none !important;
    transition: $trans;

    &:hover {
      background-color: #fff;
      box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
    }
  }

  h5 {
    margin-bottom: 11px;
    font-size: 16px;
    line-height: 22px;
  }
}

//VIDEOS

.videos {
  overflow-y: hidden;

  .header_section {
    margin-bottom: 63px;
  }

  .header_section span:after {
    height: 5000px;
  }

  &>.container {
    padding-top: 88px;
    padding-bottom: 35px;
  }

  .top_video {
    height: 100%;
    margin-bottom: 18px;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
    background-color: #000;
  }

  .embed-responsive {
    overflow: visible;
  }

  .video_desc {
    margin-bottom: 60px;
    padding-top: 6px;
    padding-bottom: 3px;
    padding-left: 10px;
    border-left: 3px solid $green;

    h4 {
      font-family: $font-pn-semibold;
      font-size: 18px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  .embed-responsive-16by9::before {
    padding-top: 0;
  }
}

//VIDEO YANDEX DIRECT

.video_yandex_direct {
  background-color: #F7F8F9;
  overflow-y: hidden;

  .header_section span:after {
    height: 2200px;
  }

  &>.container {
    padding-top: 61px;
    padding-bottom: 90px;
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 600px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }
}

//CONTACTS

.contacts {
  overflow: hidden;

  .header_section span:after {
    height: 1200px;
  }

  &>.container {
    position: relative;
    padding-top: 61px;
    padding-bottom: 80px;

    .img_bg:first-of-type {
      height: 40px;
      top: 64px;
      left: -31px;
      transform: rotate(-15deg);
    }

    .img_bg:nth-of-type(2) {
      top: 192px;
      left: -339px;
      transform: rotate(31deg);
    }

    .img_bg:nth-of-type(3) {
      height: 40px;
      top: 487px;
      left: -212px;
      transform: rotate(60deg);
    }

    .img_bg:nth-of-type(4) {
      height: 40px;
      top: 77px;
      right: -328px;
      transform: rotate(60deg);
    }

    .img_bg:nth-of-type(5) {
      top: 194px;
      right: 0;
      transform: rotate(29deg);
    }

    .img_bg:nth-of-type(6) {
      height: 150px;
      top: 375px;
      right: -279px;
    }
  }

  .header_section {
    margin-bottom: 96px;
  }

  .header_section h2 {
    max-width: 445px;
    position: relative;
    top: 28px;
  }

  .header_section span {
    top: 35px;
  }

  h3 {
    margin-bottom: 19px;
    font-family: $font-pn-bold;
    font-size: 14px;
    color: $blue;
    text-transform: uppercase;
  }

  h4 {
    margin-bottom: 42px;
    font-family: $font-pn-semibold;
    font-size: 18px;
  }

  p {
    margin-bottom: 6px;
    font-family: $font-pn-semibold;
    font-size: 16px;
    letter-spacing: 1.2px;
  }

  a.popap_a {
    color: $blue;
    text-decoration: none !important;
    border-bottom: 1px dashed $blue;
    transition: $trans;

    &:hover {
      color: $green;
      border-bottom: 1px solid transparent;
    }
  }

  .col_center {
    padding-left: 84px;
  }

  .col_right {
    padding-left: 82px;
  }

  .social_wrap {
    display: flex;
    flex-wrap: wrap;

    a {
      display: block;
      height: 30px;
      width: 30px;
      margin-right: 15px;
      border-radius: 3px;
      transition: $trans;

      &:first-of-type {
        background: url("../img/icons/whatsapp.jpg") no-repeat center;

        &:hover {
          opacity: 0.8;
          //background: url("../img/icons/whatsapp_hover.jpg") no-repeat center;
        }
      }

      &:nth-of-type(2) {
        background: url("../img/icons/vk.jpg") no-repeat center;

        &:hover {
          //background: url("../img/icons/vk_hover.jpg") no-repeat center;
          opacity: 0.8;
        }
      }

      &:nth-of-type(3) {
        background: url("../img/icons/facebook.jpg") no-repeat center;

        &:hover {
          //background: url("../img/icons/facebook_hover.jpg") no-repeat center;
          opacity: 0.8;
        }
      }
    }
  }

  .blue_btn {
    display: block;
    margin-top: 74px;
    margin-left: auto;
    margin-right: auto;
    width: 156px;

    img {
      margin-left: 10px;
    }
  }

  .contacts_bottom {
    margin-top: 40px;
    font-family: $font-pn-semibold;
    text-align: center;
    font-size: 14px;
  }
}

//FOOTER

footer {
  padding-top: 45px;
  padding-bottom: 42px;
  background-color: #EEF0FF;

  .col_left {
    font-size: 16px;
  }
  
  .col_right {
    text-align: right;

    a {
      font-size: 14px;
      color: #333;
      text-decoration: none !important;
      border-bottom: 1px dashed #333;
      transition: $trans;

      &:hover {
        border-bottom: 1px dashed transparent;
        color: $green;
      }
    }
  }
}

                          //THANKS PAGE

.thanks {
  padding-top: 200px;
  padding-bottom: 150px;
  background-color: #F7F8F9;

  h1 {
    font-family: $font-pn-bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
  }

  p {

    font-family: $font-pn-regular;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    a {
      color: $blue;
    }
  }
}

                        //PROJECTS PAGE

.projects {
  padding-top: 100px;

  .service_item {
    padding-top: 0;
    padding-bottom: 40px;
    border: 1px solid #dadada;
    border-radius: 4px;

    &>a.blue_btn {
      display: inline-block;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #fff;
    }
  }

  .project_img_wrap {
    max-height: 155px;
    margin-bottom: 20px;

    img {
      max-width: 292px;
      width: 100%;
    }
  }

  h3 {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 28px;
    color: $green;
  }

  p {
    padding-left: 10px;
    padding-right: 10px;
  }
}


                        //TEST PROJECT PAGE

.project_iframe {
  position: relative;
}

.desktop_iframe {
  width: 100%;
  height: calc(100vh - 60px);
  text-align: center;
}

.feature_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #EEF0FF;
  box-shadow: 0px -4px 18px 0px rgba(0,0,0,0.1);
  border-top: 1px solid #dadada;

  .container {
    height: 100%;
  }

  .row {
    height: 100%;
  }

  .col_left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .col_center {
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .col_right {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .more_templates {
    font-size: 16px;
    color: $blue;
    transition: $trans;
    text-decoration: none !important;
    border-bottom: 1px solid $blue;


    &:hover {
      color: $green;
      border-bottom: 1px solid transparent;
    }
  }

  .home_anchor {
    font-size: 16px;
    color: $blue;
    transition: $trans;
    text-decoration: none !important;
    border-bottom: 1px solid $blue;


    &:hover {
      color: $green;
      border-bottom: 1px solid transparent;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    height: 100%;

    li {
      cursor: pointer;

      &:first-of-type {

        a {
          border-radius: 5px 0 0 5px;
          border-right: 1px solid #dadada;
        }
      }

      &:last-of-type {

        a {
          border-radius: 0 5px 5px 0;
          border-left: 1px solid #dadada;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 50px;
        background-color: #fff;
        text-decoration: none !important;
        color: $blue;

        &.active {
          background-color: $green;
          box-shadow: inset 0 1px 2px rgba(0,0,0,.4);
        }

        &:hover {
          background-color: $green;
          box-shadow: inset 0 1px 2px rgba(0,0,0,.4);
        }
       }

      i {
        font-size: 32px;
      }
    }
  }

  .project_price {
    font-size: 20px;
    font-weight: 700;
    color: $orange;
  }

  .blue_btn {
    min-width: 160px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.bg_tablet_wrap {
  display: none;
  max-width: 1025px;
  margin-top: 40px;
  padding-bottom: 230px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  background: url("../img/ipad-309583.svg") no-repeat top;
  background-size: contain;
}

.tablet_iframe_wrap {
  max-width: 752px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;

}

.tablet_iframe {
  padding-top: 133px;
  width: 768px;
  height: 1124px;
  margin-left: auto;
  margin-right: auto;
}


.bg_mobile_wrap {
  display: none;
  max-width: 458px;
  margin-top: 40px;
  padding-bottom: 230px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  background: url("../img/iphone-7-plus-1766257.svg") no-repeat top;
  background-size: contain;
}

.mobile_iframe_wrap {
  max-width: 399px;
  //overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;

}

.mobile_iframe {
  padding-top: 100px;
  width: 399px;
  height: 812px;
  margin-left: auto;
  margin-right: auto;
}

                            //PRYVACY PAGE

.privacy {
  padding-top: 150px;
  padding-bottom: 100px;

  .header_section {
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: $font-pn-bold;
  }

  ul {

    li {
      color: $green;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;
      text-align: justify;
      
      span {
        color: #333;
      }
    }
  }

  a {
    font-size: 16px;
    line-height: 22px;
    color: $blue;
    text-decoration: none !important;
    border-bottom: 1px solid $blue;
    transition: $trans;

    &:hover {
      color: $green;
      border-bottom: 1px solid transparent;
    }
  }
}

                                          //SITES PAGE

#sites_page {

  //.main_screen .col_left {
  //  padding-top: 130px;
  //}
  .main_screen h1 {
    margin-bottom: 15px;
    font-size: 48px;
    line-height: 58px;
  }

  .main_screen h3 {
    margin-bottom: 20px;
    font-family: $font-pn-bold;
    font-size: 36px;

    span {
      color: $orange;
      font-weight: 700;
    }

    //&:last-of-type {
    //  margin-bottom: 36px;
    //}
  }

  .main_screen h6 {
    margin-bottom: 40px;
    font-size: 22px;
  }

  .main_screen .photo_name {
    max-width: 302px;
    //font-family: $font-pn-semibold;
  }

  .services {
    padding-top: 0;

    .header_section span:after {
      height: 10000px;
    }
  }

  .contacts {
    background-color: #F7F8F9;
  }
}

                                          //GOOGLE ADWORDS PAGE
#google_adwords_page {

  .main_screen .photo_name {
    max-width: 302px;
  }
}

//PRICE CREATING SITE

.price_creating_site {
  background-color: #F7F8F9;
  overflow: hidden;

  &>.container {
    position: relative;
    padding-top: 88px;
    padding-bottom: 56px;
  }

  .site_tariff_wrap {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #dadada;
    border-radius: 4px;
    transition: $trans;
    background: #fff;

    &:hover {
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    }

    &>h5 {
      margin-bottom: 20px;
      font-family: $font-pn-semibold;
      font-size: 24px;
      text-align: center;
    }
  }



  .icon_wrap {
    margin-bottom: 30px;
    text-align: center;
    color: $green;

    .fa {
      font-size: 50px;
    }
  }

  .price_wrap {
    margin-bottom: 30px;
    text-align: center;

    span {
      margin-bottom: 10px;
      display: block;
      font-family: $font-pn-bold;
      font-size: 24px;

      &.discount_price {
        font-size: 18px;
        color: red;
        text-decoration: line-through;
      }
    }
  }
  
  ul {
    margin-bottom: 30px;
    //text-align: center;
    
    li {
      margin-bottom: 5px;
      color: $blue;
      
      span {
        font-size: 14px;
        color: #333;
      }
    }
  }

  .blue_btn {
    display: block;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
}

//Button pulse style

//@-webkit-keyframes loadTransform {
//  10% {
//    box-shadow: 0 0 19px 3px rgba(0,138,192,0.57);
//  }
//
//  40% {
//    box-shadow: 0 0 19px 59px rgba(0,138,192,0.1);
//  }
//
//  100% {
//    box-shadow: 0 0 19px 39px rgba(0,138,192,0.00);
//  }
//}
//
//.but-dec {
//  position: relative;
//  text-align: center;
//  box-shadow: 0 0 0 0 rgba(250,220,139,0.17);
//}
//
//.but-dec:after {
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  bottom: 0;
//  content: '';
//  transition: 0.3s all;
//  opacity: 1;
//  box-shadow: 0 0 0 0 rgba(250,220,139,0.17);
//}
//
//.but-dec::before {
//  //border-radius: 45px !important;
//}
//
//.but-pulse:hover:before {
//  animation: loadTransform  1s  linear !important;
//}
//
//.but-pulse:before {
//  animation: loadTransform  2s infinite linear;
//}
//
//.but-pulse:before {
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  bottom: 0;
//  content: '';
//  border-radius: 3px;
//  transition: 0.3s all;
//  opacity: 1;
//  box-shadow: 0 0 0 0 rgba(250,220,139,0.17);
//}
//
//.but-pulse:before {
//  border-radius: 1px;
//}
//
//
//
//.but {
//  //background: none;
//  position: relative;
//}
//
